import { Box, Card } from '@angellist/adapt';
import React, { forwardRef, useRef, useState } from 'react';
import {
  Placement,
  useOverlay,
  useOverlayPosition,
  useOverlayTrigger,
} from '@react-aria/overlays';

export const AdaptOverlay = forwardRef((props: any, ref: any) => {
  const {
    isOpen,
    onClose,
    style,
    children,
    zIndex,
    cardProps = {},
    ...restProps
  } = props;
  const { overlayProps } = useOverlay(
    { onClose, isOpen, isDismissable: true },
    ref,
  );

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      {...overlayProps}
      {...restProps}
      outline="none"
      ref={ref}
      zIndex="popover"
      style={{ maxWidth: 360, ...(style || {}), ...(zIndex && { zIndex }) }}
    >
      <Card elevation="300" {...cardProps}>
        {children}
      </Card>
    </Box>
  );
});

AdaptOverlay.displayName = 'AdaptOverlay';

type Config = {
  positionProps?: {
    placement?: Placement;
  };
};

export const useOverlayProps = (config?: Config) => {
  const triggerRef = useRef();
  const overlayRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const overlayState = {
    isOpen,
    setOpen: (open: boolean) => setIsOpen(open),
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    toggle: () => setIsOpen(!isOpen),
  };

  const { overlayProps } = useOverlayTrigger(
    { type: 'dialog' },
    overlayState,
    triggerRef,
  );

  const { overlayProps: positionProps } = useOverlayPosition({
    targetRef: triggerRef,
    overlayRef,
    placement: 'bottom',
    isOpen,
    offset: 8,
    ...(config?.positionProps || {}),
  });

  return {
    triggerRef,
    overlayRef,
    isOpen,
    onOpen: overlayState.open,
    onClose: overlayState.close,
    overlayProps: {
      ...overlayProps,
      ...positionProps,
    },
  };
};
